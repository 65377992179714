body {
  font-family: "Open Sans", Arial, sans-serif;
  margin: 0;
}
a {
  text-decoration: none;
  transition: color 100ms ease-in-out, background 100ms ease-in-out;
}
ul,
ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}
#molectular-algorithm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 200ms ease-in-out;
}
#molectular-algorithm canvas {
  visibility: visible !important;
}
.main-header {
  width: 360px;
  color: #fff;
  position: absolute;
  top: 20px;
  left: 20px;
}
.main-header a {
  color: #fff;
}
.main-header a:hover {
  color: #ccc;
}
.main-nav,
.main-nav ul,
.main-nav ul li,
.main-nav ul a,
display block {
  width: 100%;
  box-sizing: border-box;
  float: left;
}
.main-nav ul li {
  width: auto;
  margin-left: 1px;
}
.main-nav ul li a {
  padding: 8px 12px;
  background-color: #000;
}
.main-nav ul li a:hover {
  background-color: #f5f5f5;
  color: #000;
}
.main-nav ul li:first-child a {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.main-nav ul li:last-child a {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
